import {RecipeDetailModel, RecipeWeeks} from '@mfb/cookbook';
import {Jumbotron, StickyHeader} from '@mfb/lego';
import classnames from 'classnames';
import {graphql, StaticQuery} from 'gatsby';
import {first, flatten, orderBy, take, uniq} from 'lodash';
import * as React from 'react';
import {forceCheck} from 'react-lazyload';

import {ProductType} from '../../gatsbyLib/data/CoreApiClient';
import SelectDropdown from '../components/Dropdown';
import {mfbScroll} from '../components/MFBScroll';
import {MetadataContentModel} from '../contentful/metadata/MetadataContentModel';
import {ExternalPaths, WithPathRules} from '../contentful/PathRulesHoc';
import {RecipeInfoQueryModel} from '../contentful/productCard/AllProductsQueryModel';
import {WithSiteMetadata} from '../contentful/SiteMetadataHoc';
import {SiteQueryModel} from '../contentful/SiteMetadataQueryModel';
import {getNodes} from '../customGraphQlModels/Edges';
import PageTemplate from '../templates/PageTemplate';
import {getWeeksFromRecipeCollectionModel} from '../util/getWeeksFromRecipeCollectionModel';
import {getWeekToRemove} from '../util/getWeekToRemove';

interface State {
  week: string | undefined;
  isModalOpen: boolean;
  recipeDetailModel: RecipeDetailModel | undefined;
}

class PureRecipes extends React.PureComponent<
  ExternalPaths & SiteQueryModel,
  State
> {
  public constructor(props: ExternalPaths & SiteQueryModel) {
    super(props);

    this.state = {
      week: undefined,
      isModalOpen: false,
      recipeDetailModel: undefined,
    };
  }

  private handleWeekChange(week: string) {
    this.setState({week}, forceCheck);
  }

  public render() {
    const {siteMetadata} = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            allProductDb(
              filter: {
                sku: {
                  nin: [
                    "default"
                    "MFB9484"
                    "MFB9485"
                    "MFB9486"
                    "MFB9487"
                    "MFB9488"
                    "MFB9489"
                  ]
                }
                brand: {in: ["MFB", "FS"]}
                availableFrequency: {ne: 4}
              }
            ) {
              edges {
                node {
                  ...ProductDbFragment
                }
              }
            }
          }
        `}
        render={(data: RecipeInfoQueryModel) => {
          const ordered = orderBy(getNodes(data.allProductDb), [
            product => product.name.toLowerCase(),
          ]);
          let weeks: Array<string> = [];
          const primaryProducts = ordered.filter(
            o => o.productType === ProductType.Primary
          );

          let bgToggle = false;

          const skus = primaryProducts.map(pe => {
            if (pe.recipeCollections && pe.recipeCollections.length > 0) {
              const items = flatten(
                pe.recipeCollections.map(rc => rc.collections)
              );

              // We load recipes starting from the current week.
              // On this page we only want to show the recipes starting from the next available delivery week.
              const weekToRemove = getWeekToRemove();
              weeks = getWeeksFromRecipeCollectionModel(items).filter(
                w => w !== weekToRemove
              );

              const week = this.state.week || first(weeks);
              const allRecipes = items
                .filter(item => item.day === week)
                .map(rc => {
                  rc.recipes = rc.recipes.filter(r => r.surcharge === 0);

                  return rc;
                });

              if (allRecipes.length > 0) {
                bgToggle = !bgToggle;

                return (
                  <div
                    key={`${pe.id}-${week}`}
                    className={classnames({'bg-secondary': bgToggle})}
                  >
                    <RecipeWeeks
                      key={pe.id}
                      id={pe.id}
                      title={pe.name}
                      showWeekSelector={false}
                      // Removed for now as this order flow doesn't do a postcode check to ensure the relevant DC has capacity
                      // button={{label: 'Order Now', url: `/order/${pe.sku}`}}
                      recipeCollections={allRecipes}
                      isRecipeModalEnabled={true}
                    />
                  </div>
                );
              }

              return (
                <div
                  key={`${pe.id}-${week}`}
                  className={classnames({'bg-secondary': bgToggle})}
                >
                  <RecipeWeeks
                    key={pe.id}
                    id={pe.id}
                    title={pe.name}
                    showWeekSelector={false}
                    isRecipeModalEnabled={true}
                    // Removed for now as this order flow doesn't do a postcode check to ensure the relevant DC has capacity
                    // button={{label: 'Order Now', url: `/order/${pe.sku}`}}
                    recipeCollections={allRecipes}
                  />
                </div>
              );
            }
          });

          const weekSelection = take(uniq(weeks), 2).map((w, i) => {
            // ['Sunday', '15', 'Febuary']
            const spitString = w.split(' ') || [];

            // remove first item aka 'Sunday'
            spitString.shift();

            // change 'Feburary' to 'Feb'
            const displayWeek = spitString
              .map(dw => dw.substring(0, 3))
              .join(' ');

            return (
              <button
                onClick={() => this.handleWeekChange(w)}
                key={w}
                className={classnames(
                  'btn ml-2 px-2 px-md-3 px-lg-4',
                  this.state.week === w ||
                    (this.state.week === undefined && i === 0)
                    ? 'btn-primary'
                    : 'btn-secondary'
                )}
              >
                {displayWeek}
              </button>
            );
          });

          const dropdown = (
            <div className="d-flex justify-content-center">
              <SelectDropdown
                placeholder="Bag"
                options={primaryProducts
                  .filter(
                    pe =>
                      pe.recipeCollections &&
                      pe.recipeCollections.length > 0 &&
                      pe.recipeCollections.some(c => c.collections.length > 0)
                  )
                  .map(cd => ({id: cd.id, label: cd.name}))}
                onSelect={t => mfbScroll(t, -90)}
              />
              {weekSelection}
            </div>
          );

          const description =
            'Our menus are all about quality and freshness, so we use what’s in season and the best ingredients. We’re committed to using locally grown meat, seafood, fruit and veggies, sourced from New Zealand’s finest producers whenever possible.';

          const metadata: MetadataContentModel = {
            title: 'My Food Bag Recipes by Nadia Lim - My Food Bag',
            description: {
              description,
            },
            type: 'article',
            canonicalUrlLink: {
              absoluteUrl: '/recipes',
            },
            sharingLinkImage: {
              id: '',
              __typename: '',
              alternateText: '',
              small: {
                id: '',
                file: {
                  url: '',
                },
              },
              large: {
                id: '',
                file: {
                  url: '',
                },
              },
            },
            allowIndexing: siteMetadata.brand === 'MFB',
            keywords: [],
          };

          return (
            <PageTemplate
              metadata={metadata}
              render={() => (
                <>
                  <Jumbotron
                    img={{}}
                    id="jumbo-recipes"
                    title="Our Delicious Recipes"
                    description={description}
                    splitOnMobile={false}
                  >
                    {dropdown}
                    <StickyHeader text={'Select a Bag'} actionItem={dropdown} />
                  </Jumbotron>
                  {skus}
                </>
              )}
            />
          );
        }}
      />
    );
  }
}

const Recipes = WithPathRules(WithSiteMetadata(PureRecipes));

export default Recipes;
