import * as React from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

interface State {
  isOpen: boolean;
  height: number;
}

interface Option {
  id: string;
  label: string;
}

interface Props {
  options: Array<Option>;
  placeholder?: string;
  onSelect(target: string): void;
}

class SelectDropdown extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {isOpen: false, height: 0};
  }

  public toggle() {
    this.setState({isOpen: !this.state.isOpen});
  }

  public click(target: string) {
    const {onSelect} = this.props;
    onSelect(target);
  }

  public componentDidMount() {
    const id = this.fetchId();
    const element = document && document.getElementById(id);
    const location = element && element.getBoundingClientRect();
    const height = (location && location.height * 2) || 0;

    this.setState({height});
  }

  public fetchId() {
    return this.props.options.map(m => m.id).join();
  }

  public render() {
    const {options, placeholder} = this.props;
    const {isOpen} = this.state;
    const id = this.fetchId();

    return (
      <div id={id}>
        <Dropdown isOpen={isOpen} toggle={() => this.toggle()}>
          <DropdownToggle caret className="w-100 px-3 px-md-4">
            {placeholder || 'Select an option'}
          </DropdownToggle>
          <DropdownMenu
            style={{
              maxHeight: `calc(100vh - ${this.state.height}px)`,
              overflow: 'auto',
            }}
          >
            {options.map(cpg => (
              <DropdownItem key={cpg.id} onClick={() => this.click(cpg.id)}>
                <span style={{fontSize: '14px'}} className="d-sm-none">
                  {cpg.label}
                </span>
                <span
                  style={{fontSize: '16px'}}
                  className="d-none d-sm-block d-md-none"
                >
                  {cpg.label}
                </span>
                <span className="d-none d-md-block">{cpg.label}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export default SelectDropdown;
